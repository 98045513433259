import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import "./styles/card.css";
import {
  Card,
  InputGroup,
  ListGroup,
  FormControl,
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";

const MyPageCard = ({ title, currentUser, setCurrentUser }) => {
  const [email, setEmail] = useState("");
  const [redirect, setRedirect] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [emailExpand, setEmailExpand] = useState(false);
  const [passwordExpand, setPasswordExpand] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [smsCodeSent, setSmsCodeSent] = useState(false);
  const [userMessageEmail, setUserMessageEmail] = useState({
    showMessage: false,
    message: "",
    messageType: "danger",
  });
  const [userMessagePassword, setUserMessagePassword] = useState({
    showMessage: false,
    message: "",
    messageType: "danger",
  });

  const [userMessageName, setUserMessageName] = useState({
    showMessage: false,
    message: "",
    messageType: "danger",
  });

  const toggle = (toggleReference) => {
    switch (toggleReference) {
      case "email":
        setEmailExpand(!emailExpand);
        setUserMessageEmail({
          showMessage: false,
          message: "",
          messageType: "danger",
        });
        setUserMessageName({
          showMessage: false,
          message: "",
          messageType: "danger",
        });
        break;
      case "password":
        setPasswordExpand(!passwordExpand);
        setSmsCodeSent(false);
        setUserMessagePassword({
          showMessage: false,
          message: "",
          messageType: "danger",
        });
        break;
      default:
        break;
    }
  };

  const msisdn_string =
    "+" +
    currentUser.mobile.substring(0, 2) +
    " " +
    currentUser.mobile.substring(2, 4) +
    " " +
    currentUser.mobile.substring(4, 6) +
    " " +
    currentUser.mobile.substring(6, 8) +
    " " +
    currentUser.mobile.substring(8, 10);

  const handleChange = (e) => {
    const name = e.currentTarget.name;
    switch (name) {
      case "email":
        setEmail(e.currentTarget.value);
        break;
      case "firstname":
        setFirstname(e.currentTarget.value);
        break;
      case "lastname":
        setLastname(e.currentTarget.value);
        break;
      case "currentPassword":
        setCurrentPassword(e.currentTarget.value);
        break;
      case "newPassword":
        setNewPassword(e.currentTarget.value);
        break;
      case "smsCode":
        setSmsCode(e.currentTarget.value);
        break;
      case "confirmNewPassword":
        setConfirmNewPassword(e.currentTarget.value);
        break;
      default:
        break;
    }
  };

  const handleSubmitEmail = () => {
    if (email == "" || email == " ") {
      setUserMessageEmail({
        showMessage: true,
        message: "E-postadresse kreves",
        messageType: "alert-danger",
      });
    } else {
      const userdata = { ...currentUser, email: email };

      const currentToken = AuthService.getCurrentToken();

      UserService.changeUser(currentToken.access_token, userdata).then(
        (response) => {
          //setCurrentUser(response);
          setUserMessageEmail({
            showMessage: true,
            message: "E-post endret til " + email + ".",
            messageType: "alert-success",
          });
        },
        () => {
          setUserMessageEmail({
            showMessage: true,
            message: "Noe gikk galt i endring av E-post.",
            messageType: "alert-danger",
          });
        },
      );
    }
  };

  const handleSendSmsCode = () => {
    UserService.sendSMSCode(currentUser.mobile).then(
      (response) => {
        setSmsCodeSent(true);
        //setCurrentUser(response);
        setUserMessagePassword({
          showMessage: true,
          message: "sms-kode sendt til din mobil",
          messageType: "alert-success",
        });
      },
      () => {
        setUserMessagePassword({
          showMessage: true,
          message: "Noe gikk galt i endring av E-post.",
          messageType: "alert-danger",
        });
      },
    );
  };

  const handleSubmitPassword = () => {
    if (newPassword === confirmNewPassword) {
      const userdata = {
        mobile: currentUser.mobile,
        code: smsCode,
        password: newPassword,
      };
      //const currentToken = AuthService.getCurrentToken();
      UserService.changePassword(userdata).then(
        (response) => {
          //setCurrentUser(response);
          setUserMessagePassword({
            showMessage: true,
            message: "Passord endret.",
            messageType: "alert-success",
          });
        },
        () => {
          setUserMessagePassword({
            showMessage: true,
            message: "Noe gikk galt i endring av Passord",
            messageType: "alert-danger",
          });
        },
      );

      /*AuthService.login(currentUser.mobile, currentPassword).then(
        () => {
          const userdata = {mobile: currentUser.mobile, code: smsCode, password: newPassword };
          //const currentToken = AuthService.getCurrentToken();
          UserService.changePassword(userdata).then(
            (response) => {
              //setCurrentUser(response);
              setUserMessagePassword({
                showMessage: true,
                message: "Passord endret.",
                messageType: "alert-success",
              });
            },
            () => {
              setUserMessagePassword({
                showMessage: true,
                message: "Noe gikk galt i endring av Passord",
                messageType: "alert-danger",
              });
            }
          );
        },
        () => {
          setUserMessagePassword({
            showMessage: true,
            message: "Nåværende passord stemmer ikke.",
            messageType: "alert-danger",
          });
        }
      );*/
    } else {
      setUserMessagePassword({
        showMessage: true,
        message: "Passordene matcher ikke.",
        messageType: "alert-danger",
      });
    }
  };
  const handleChangeCheck = () => {
    const userData = { ...currentUser, newsletter: !currentUser.newsletter };
    setCurrentUser(userData);
    const currentToken = AuthService.getCurrentToken();
    UserService.changeUser(currentToken.access_token, userData);
  };

  const handleSubmitName = () => {
    /*const userdata = {
        firstname: firstname,
        lastname: lastname,
        email: currentUser.email,
        newsletter: currentUser.new,

      };*/
    const userdata = {
      ...currentUser,
      firstname: firstname,
      lastname: lastname,
    };
    //console.log(userdata);
    const currentToken = AuthService.getCurrentToken();

    UserService.changeUser(currentToken.access_token, userdata).then(
      (response) => {
        UserService.getUserFromToken(currentToken.access_token)
          .then((user) => {
            UserService.userGetLog("MyPage Card Page 1", user, currentUser);
            setCurrentUser(user);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              const errorMsg = error.response.data.message;
              if (
                errorMsg.includes("Token not found") ||
                errorMsg.includes("Token expired") ||
                errorMsg.includes("Invalid Token")
              ) {
                UserService.userGetLog(
                  "error MyPage Card Page: " + currentToken.access_token,
                  errorMsg,
                  JSON.stringify(currentUser),
                );
                AuthService.logout();
                setRedirect({ redirect: "/logg-inn" });
              }
              console.log("new error  mypage 1", error.response.data.message); // Handle error message here
            } else {
              console.log("An unexpected error occurred");
            }
          });
        setUserMessageName({
          showMessage: true,
          message: "Ditt navn har blitt oppdatert",
          messageType: "alert-success",
        });
      },
      () => {
        setUserMessageName({
          showMessage: true,
          message: "Noe gikk galt, prøv igjen senere.",
          messageType: "alert-danger",
        });
      },
    );
  };
  if (redirect) {
    return <Redirect to={redirect.redirect} />;
  }
  return (
    <>
      <Card className="card-main">
        <Card.Header>
          <div className="bold-text">{title}</div>
        </Card.Header>
        <Card.Body className="card-body">
          <Card.Text as="div" className="card-text">
            {currentUser.firstname || currentUser.lastname ? (
              <Row>
                <Col lg={4} xs={5} md={5}>
                  Navn:
                </Col>
                <Col xs={7} md={7} className="info-col">
                  {currentUser.firstname} {currentUser.lastname}
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col lg={4} xs={5} md={5}>
                Mobilnummer:
              </Col>
              <Col xs={7} md={7} className="info-col">
                <div className="bold-text">{msisdn_string}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={4} xs={5} md={5}>
                E-post:
              </Col>
              <Col xs={7} md={7} className="info-col">
                {currentUser.email}
              </Col>
            </Row>

            <Row>
              <br></br>
            </Row>

            <Form.Check
              type="checkbox"
              label="Ja takk! Jeg vil motta nyhetsbrev på e-post fra Fabel med anbefalinger, eksklusive tilbud og personlig tilpassede tips."
              checked={currentUser.newsletter}
              onChange={handleChangeCheck}
            />
            <br />
          </Card.Text>

          <ListGroup className="list-grp">
            <ListGroup.Item className="list-item">
              Endre brukeropplysninger
              <button
                className={
                  emailExpand
                    ? " expand-btn expandtransformation"
                    : "expand-btn"
                }
                onClick={() => toggle("email")}
              >
                <span className="drp-icon">&#10148;</span>
              </button>
              {userMessageEmail.showMessage && emailExpand ? (
                <div
                  className={userMessageEmail.messageType + " alert card-alert"}
                  role="alert"
                >
                  {userMessageEmail.message}
                </div>
              ) : null}
              {userMessageName.showMessage && emailExpand ? (
                <div
                  className={userMessageName.messageType + " alert card-alert"}
                  role="alert"
                >
                  {userMessageName.message}
                </div>
              ) : null}
              {emailExpand ? (
                <div className="expand-box">
                  <InputGroup>
                    <FormControl
                      placeholder={
                        currentUser.email ? currentUser.email : "Ny e-post"
                      }
                      name="email"
                      className="fabel-input"
                      onChange={handleChange}
                    />
                    <Button
                      variant="primary"
                      className="btn-fabel"
                      onClick={handleSubmitEmail}
                    >
                      Bekreft
                    </Button>
                  </InputGroup>
                  <InputGroup>
                    <FormControl
                      placeholder={
                        currentUser.firstname
                          ? currentUser.firstname
                          : "Fornavn"
                      }
                      name="firstname"
                      className="fabel-input"
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <InputGroup>
                    <FormControl
                      placeholder={
                        currentUser.lastname
                          ? currentUser.lastname
                          : "Etternavn"
                      }
                      name="lastname"
                      className="fabel-input"
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <Button
                    className="btn-fabel float-right confirm-name-btn"
                    onClick={handleSubmitName}
                  >
                    Bekreft
                  </Button>
                </div>
              ) : null}
            </ListGroup.Item>
            <ListGroup.Item className="list-item">
              Endre passord
              <button
                className={
                  passwordExpand
                    ? " expand-btn expandtransformation"
                    : "expand-btn"
                }
                onClick={() => toggle("password")}
              >
                <span className="drp-icon">&#10148;</span>
              </button>
              {userMessagePassword.showMessage && passwordExpand ? (
                <div
                  class={userMessagePassword.messageType + " alert card-alert"}
                  role="alert"
                >
                  {userMessagePassword.message}
                </div>
              ) : null}
              {passwordExpand ? (
                <div className="password-reset-container">
                  {smsCodeSent ? (
                    <>
                      <InputGroup className="input-group">
                        <FormControl
                          placeholder="Tast inn SMS-Kode"
                          className="fabel-input"
                          name="smsCode"
                          type="number"
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="input-group">
                        <FormControl
                          placeholder="Nytt passord"
                          name="newPassword"
                          className="fabel-input"
                          type="password"
                          onChange={handleChange}
                        />
                        <br />
                      </InputGroup>
                      <InputGroup className="input-group">
                        <FormControl
                          placeholder="Bekreft nytt passord"
                          name="confirmNewPassword"
                          className="fabel-input"
                          type="password"
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="input-group input-group-btn-container">
                        <Button
                          variant="primary"
                          className="confirm-password-btn btn-fabel"
                          onClick={handleSubmitPassword}
                        >
                          Bekreft
                        </Button>
                      </InputGroup>
                    </>
                  ) : (
                    <>
                      <InputGroup className="input-group">
                        <FormControl
                          placeholder={currentUser.mobile}
                          className="fabel-input"
                          readOnly
                          name="mobilenumber"
                          type="text"
                        />
                      </InputGroup>
                      <InputGroup className="input-group input-group-btn-container">
                        <Button
                          variant="primary"
                          className="confirm-password-btn btn-fabel"
                          onClick={handleSendSmsCode}
                        >
                          Send SMS-kode
                        </Button>
                      </InputGroup>
                    </>
                  )}
                </div>
              ) : null}
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default MyPageCard;
