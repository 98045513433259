import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
    Elements,
    CardExpiryElement,
    CardNumberElement,
    CardCvcElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Row, Col, Button } from "react-bootstrap";
import UserService from "../../services/UserService";
import AuthService from "../../services/AuthService";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const StripeWidget = ({
    currentUser,
    setCurrentUser,
    currentProduct,
    currentState,
}) => {
    return (
        <Elements stripe={stripePromise}>
            <StripeChild
                setCurrentUser={setCurrentUser}
                currentUser={currentUser}
                setCurrentProduct={currentProduct}
                setCurrentState={currentState}
            />
        </Elements>
    );
};

const StripeChild = ({
    setCurrentUser,
    currentUser,
    currentProduct,
    currentState,
}) => {
    const [userMessage, setUserMessage] = useState({
        showMessage: false,
        message: "",
        messageType: null,
    });
    const [subState, setSubState] = useState();
    const [redirect, setRedirect] = useState(null);
    const [productID, setProductId] = useState();
    useEffect(() => {
        const currentToken = AuthService.getCurrentToken();
        UserService.getSubscription(currentToken.access_token)
            .then((subscription) => {
                setSubState(subscription.state);
                setProductId(subscription.product_id);
            })
            .catch((error) => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    const errorMsg = error.response.data.message;
                    if (
                        errorMsg.includes("Token not found") ||
                        errorMsg.includes("Token expired") ||
                        errorMsg.includes("Invalid Token")
                    ) {
                        AuthService.logout();
                        setRedirect({ redirect: "/logg-inn" });
                    }
                    console.log(
                        "new error  mypage 1",
                        error.response.data.message,
                    ); // Handle error message here
                } else {
                    console.log("An unexpected error occurred");
                }
            });
    }, []);

    const elements = useElements();
    const stripe = useStripe();
    const stripeSubmit = () => {
        const currentToken = AuthService.getCurrentToken();

        UserService.stripeSetupIntent(currentToken.access_token).then(
            (response) => {
                const clientSecret = response.client_secret;
                const cardElement = elements.getElement(CardNumberElement);
                stripe
                    .confirmCardSetup(clientSecret, {
                        payment_method: {
                            card: cardElement,
                        },
                    })
                    .then((result) => {
                        if (result.error) {
                            setUserMessage({
                                showMessage: true,
                                message: "Betaling feilet.",
                                messageType: "alert-danger",
                            });
                            UserService.paymentSwitchLogging(
                                "Stripe Card Setup Failed - " +
                                    JSON.stringify(result.status),
                                JSON.stringify(result),
                                currentUser.mobile,
                                subState,
                                productID,
                                currentToken.access_token,
                                result.setupIntent.payment_method,
                            );

                            console.log(
                                "payment/card error: " + JSON.stringify(result),
                            );
                        } else {
                            UserService.paymentSwitchLogging(
                                "Stripe Card Setup Success - " +
                                    JSON.stringify(result.status),
                                JSON.stringify(result),
                                currentUser.mobile,
                                subState,
                                productID,
                                currentToken.access_token,
                                result.setupIntent.payment_method,
                            );

                            UserService.stripePaymentMethod(
                                currentToken.access_token,
                                result.setupIntent.payment_method,
                            ).then(
                                (response) => {
                                    UserService.paymentSwitchLogging(
                                        "successPaymentWithStripe - " +
                                            JSON.stringify(response.status),
                                        JSON.stringify(response),
                                        currentUser.mobile,
                                        subState,
                                        productID,
                                        currentToken.access_token,
                                        result.setupIntent.payment_method,
                                    );
                                    window.location.reload();
                                    currentUser = {
                                        ...currentUser,
                                        payment_type: 3,
                                    };
                                    setCurrentUser(currentUser);
                                    setUserMessage({
                                        showMessage: true,
                                        message: "Endret til kortbetaling.",
                                        messageType: "alert-success",
                                    });
                                },
                                (error) => {
                                    UserService.paymentSwitchLogging(
                                        "failedPaymentWithStripe - " +
                                            JSON.stringify(error.status),
                                        JSON.stringify(error),
                                        currentUser.mobile,
                                        subState,
                                        productID,
                                        currentToken.access_token,
                                        result.setupIntent.payment_method,
                                    );
                                    console.log(
                                        "stripePaymentMethod error: " +
                                            JSON.stringify(error),
                                    );
                                    setUserMessage({
                                        showMessage: true,
                                        message:
                                            "Noe gikk galt, prøv igjen senere.",
                                        messageType: "alert-danger",
                                    });
                                },
                            );
                        }
                    });
            },
            () => {
                setUserMessage({
                    showMessage: true,
                    message: "Noe gikk galt, prøv igjen senere.",
                    messageType: "alert-danger",
                });
            },
        );
    };
    if (redirect) {
        return <Redirect to={redirect.redirect} />;
    }
    return (
        <>
            {userMessage.showMessage ? (
                <div
                    className={userMessage.messageType + " alert card-alert"}
                    role="alert"
                >
                    {userMessage.message}
                </div>
            ) : null}
            <Row className="card-row">
                <CardNumberElement className="stripeelement" />
            </Row>
            <Row className="card-row">
                <Col className="expiration-col">
                    <CardExpiryElement className="stripeelement" />
                </Col>
                <Col className="cvc-col">
                    <CardCvcElement className="stripeelement" />
                </Col>
            </Row>
            <Button
                variant="success"
                className="btn-card-confirm btn-success"
                onClick={stripeSubmit}
            >
                Bekreft
            </Button>
            <div className="stripe-text">
                <br />
                Ønsker du oversikt over tidligere transaksjoner på kort ber vi
                deg ta kontakt med kundeservice på kontakt@fabel.no​
            </div>
        </>
    );
};

export default StripeWidget;
