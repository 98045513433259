import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";

const StrexWidget = ({ currentUser, setCurrentUser, currProduct }) => {
  console.log("currentUser", currentUser, "setCurrentUser", setCurrentUser);
  const [subState, setSubState] = useState();
  const [redirect, setRedirect] = useState(null);
  const [productID, setProductId] = useState();
  const [userMessage, setUserMessage] = useState({
    showMessage: false,
    message: "",
    messageType: null,
  });
  useEffect(() => {
    const currentToken = AuthService.getCurrentToken();
    UserService.getSubscription(currentToken.access_token)
      .then((subscription) => {
        setSubState(subscription.state);
        setProductId(subscription.product_id);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const errorMsg = error.response.data.message;
          if (
            errorMsg.includes("Token not found") ||
            errorMsg.includes("Token expired") ||
            errorMsg.includes("Invalid Token")
          ) {
            AuthService.logout();
            setRedirect({ redirect: "/logg-inn" });
          }
          console.log("new error  mypage 1", error.response.data.message); // Handle error message here
        } else {
          console.log("An unexpected error occurred");
        }
      });
  }, []);
  const handleStrexSubmit = () => {
    const currentToken = AuthService.getCurrentToken();
    UserService.changeStrexProduct(currentToken.access_token, "").then(
      (response) => {
        UserService.paymentSwitchLogging(
          "successPaymentWithStrex -" + JSON.stringify(response.status),
          JSON.stringify(response),
          currentUser.mobile,
          subState,
          productID,
          currentToken.access_token,
          "",
        );
        console.log("strexPaymentMethod success: " + JSON.stringify(response));
        window.location.reload();
      },
      (error) => {
        UserService.paymentSwitchLogging(
          "failedPaymentWithStrex -" + JSON.stringify(error.status),
          JSON.stringify(error),
          currentUser.mobile,
          subState,
          productID,
          currentToken.access_token,
          "",
        );
        console.log("strexPaymentMethod error: " + JSON.stringify(error));
        setUserMessage({
          showMessage: true,
          message: "Noe gikk galt, prøv igjen senere.",
          messageType: "alert-danger",
        });
        //console.log("error geting subscription 4.3");
      },
    );
    /* const userNewPaymentType = {...currentUser, payment_type: 0}

                const vippsData = {
                  "product_id": currProduct,
                  "campaign_code": "UTMCAMPAIGN",
                  "redirect_url": window.location.origin+"/vipps-completed"
                }
                UserService.vippsIntent(currentToken.access_token, vippsData)
                .then((response) => {
                    if(response.status===200){
                      window.sessionStorage.setItem('vippsreference', response.data.reference);
                      window.location.href = response.data.confirmation_url
                      //setVippsButtonUrl(vipps.data.confirmation_url);
                    }
                    setCurrentUser(response)
                    setUserMessage({
                        showMessage: true,
                        message: "Byttet til mobilbetaling",
                        messageType: "alert-success"
                    })
                }, () => {
                    setUserMessage({
                        showMessage: true,
                        message: "Noe gikk galt, prøv igjen senere.",
                        messageType: "alert-danger"
                    })
                })*/
  };
  if (redirect) {
    return <Redirect to={redirect.redirect} />;
  }
  return (
    <Card className="strex-card">
      <Card.Title className="strex-title">Betal med mobilregning</Card.Title>
      <Card.Text as="div" className="strex-text">
        {userMessage.showMessage ? (
          <div
            className={userMessage.messageType + " alert card-alert"}
            role="alert"
          >
            {userMessage.message}
          </div>
        ) : null}
        Velger du å betale med mobilregning trenger du ikke å registrere
        kortinformasjon. Abonnementet vil bli belastet mobilregningen din.
        Oversikt over alle transaksjoner finner du på strex.no.
        <br />
        <br />
        <Button
          className="strex-confirm btn-success"
          onClick={handleStrexSubmit}
        >
          Betal med mobilregning
        </Button>
      </Card.Text>
    </Card>
  );
};

export default StrexWidget;
