import React, { useState } from "react";
import "../styles/login.css";
import {
  Container,
  Form,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ValidateInput from "../services/ValidateInput";
import { useHistory } from "react-router-dom";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import Cookies from "universal-cookie";

const Login = ({ setToken, callback }) => {
  //define state
  //const irow = useState("");
  const cookies = new Cookies();
  const [msisdn, setMsisdn] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [encryptUserPass, setEncUserPass] = useState("");
  const [loginCredError, setloginCredError] = useState(false);
  const [submitState, setSubmitState] = useState({
    submitted: false,
    isError: false,
    message: "",
  });

  //access useHistory
  let history = useHistory();
  //handle input change
  const handleChange = (e) => {
    const name = e.currentTarget.name;
    if (name === "msisdn") {
      setMsisdn(47 + e.currentTarget.value);
      setUserName(e.currentTarget.value);
    } else if (name === "password") {
      setPassword(e.currentTarget.value);
    }
  };

  //handlesubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    const stateUpdate = ValidateInput(msisdn, password);
    setSubmitState(stateUpdate);
    if (stateUpdate.isError === false && stateUpdate.submitted === true) {
      AuthService.login(msisdn, password).then(
        () => {
          setSubmitState(stateUpdate);
          setloginCredError(false);
          const currentToken = AuthService.getCurrentToken();
          //const encryptedUserPass = AuthService.getEncryptedCredentials(username, password);

          //console.log('encryptedUserPass', encryptedUserPass);
          //return;
          const userSubscription = UserService.getSubscription(
            currentToken.access_token,
          ).then(
            (subscription) => {},
            (error) => {},
          );
          const dataPromise = userSubscription
            .then((response) => {
              window.location.replace("/min-side");
              //history.push("min-side");
              //window.location.reload();
              //const encryptedData = encryptedUserPass
              //.then((response) => {
              //const link = process.env.REACT_APP_FABEL_WP_URL+"SSO/sso_login_v2.php?secret=fabel_sso_key&url="+process.env.REACT_APP_FABEL_WP_URL+"lydbok&cred=" +encodeURI(response)
              //const link = process.env.REACT_APP_FABEL_WP_URL+"SSO/sso_login_v2.php?secret=fabel_sso_key&url="+process.env.REACT_APP_FABEL_WP_URL+"lydbok&username=" +btoa(username) +"&password=" +btoa(password)
              //cookies.set('link', link, { path: '/' });
              //history.push("min-side");
              // window.location.reload();
              // })
            })
            .catch((error) => {
              console.log("error: " + error);
              window.location.replace("/min-side");
              //history.push("/min-side");
              //window.location.reload();
              //console.log("fabel_connect_token_v22", window.sessionStorage.getItem('fabel_connect_token_v2'));
              //const encryptedData = encryptedUserPass
              //.then((response) => {
              //const link = process.env.REACT_APP_FABEL_WP_URL+"SSO/sso_login_v2.php?secret=fabel_sso_key&url="+process.env.REACT_APP_FABEL_WP_URL+"lydbok&cred=" +encodeURI(response)
              //const link = process.env.REACT_APP_FABEL_WP_URL+"SSO/sso_login_v2.php?secret=fabel_sso_key&url="+process.env.REACT_APP_FABEL_WP_URL+"lydbok&username=" +btoa(username) +"&password=" +btoa(password)
              //cookies.set('link', link, { path: '/' });
              //})
            });
        },
        (error) => {
          setloginCredError(true);
          /*const resMessage = 'Glemt passordet ditt, klikk <span onClick={history.push("/tilbakestille-passorde?username="'+username+')}>her</span> for å tilbakestille.';
          setSubmitState({
            submitted: true,
            isError: true,
            message: resMessage,
          });*/
        },
      );
    }
  };

  const goToPasswordRecovery = () => {
    window.sessionStorage.setItem("usernameParam", username);
    history.push("/tilbakestille-passord");
    //history.push("/sms-kode");
  };

  const goToResetPassword = () => {
    history.push("/sms-kode");
  };
  return (
    <>
      <Container className="login-form-container">
        <div className="title">
          <h1 id="title">LOGG INN</h1>
        </div>
        {loginCredError ? (
          <div class="alert alert-positive fabel-alert" role="alert">
            Glemt passordet ditt, klikk{" "}
            <a
              style={{ cursor: "pointer", color: "#0d6efd" }}
              onClick={goToPasswordRecovery}
            >
              her
            </a>{" "}
            for å tilbakestille.
          </div>
        ) : null}

        {submitState.isError ? (
          <div class="alert alert-danger fabel-alert" role="alert">
            {submitState.message}
          </div>
        ) : null}

        <Form className="login-form" onSubmit={handleSubmit}>
          <span className="msisdn">Mobilnummer</span>
          <FormGroup className="mb-3">
            <FormControl
              name="msisdn"
              placeholder="mobilnummer"
              onChange={handleChange}
              type="number"
              className="fabel-input"
            />
          </FormGroup>
          <span className="password">Passord</span>
          <FormGroup className="mb-3">
            <FormControl
              name="password"
              placeholder="passord"
              onChange={handleChange}
              type="password"
              className="fabel-input"
            />
          </FormGroup>
          <Button className="login-btn btn-fabel" type="submit">
            Logg inn
          </Button>
          <div className="form-link">
            <Button variant="linK" onClick={goToResetPassword}>
              Glemt passord?
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default Login;
